<template>
  <v-dialog v-model="show" max-width="400px">
    <v-card>
      <v-card-title> Justificativa de decisão </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="12">
            <v-textarea
              v-model="suggestionToSave.justificativa"
              label="Justificativa"
              :counter="255"
              outlined
              persistent-placeholder
              :placeholder="obrigatorio"
              :rules="[rules.required]"
            />
          </v-col>
          <div class="summary text-red" v-if="error">{{ error }}</div>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close"> Cancelar</v-btn>
          <!-- Retirado na estória AG-303 -->
          <!-- <v-btn color="error" text @click="reprove"> Reprovar</v-btn> -->
          <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="save"> Aprovar </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card></v-dialog
  >
</template>
<script>
import eSocialService from '@/services/eSocialService.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalJustify',
  props: ['suggestion', 'rubric'],
  data() {
    return {
      error: '',
      obrigatorio: 'Obrigatória',
      show: false,
      loadingSave: false,
      activeColor: 'primary',
      rules: {
        required: (value) => !!value || 'Campo obrigatório.',
      },
    };
  },
  computed: {
    suggestionToSave() {
      return this.suggestion;
    },
    rubricInCaseReproved() {
      return this.rubric;
    },
    ...mapGetters({
      usuario: 'user/getUserDetails',
    }),
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },
    open() {
      this.show = true;
    },
    async save() {
      let itemWithDesc = cloneDeep(this.suggestionToSave);
      let itemToSave = this.remountItem(itemWithDesc);
      if (itemToSave.justificativa != null) {
        itemToSave.status = 'PENDENTE_PARAMETRIZACAO';
        itemToSave.dataRegistro = new Date();
        let save = { idEvtTabRubrica: this.rubricInCaseReproved.id, rubricaHistorico: itemToSave };

        await eSocialService.rubricahistorico.save(save);
        this.close();
        this.$emit('close');
      } else {
        this.error = 'Por favor justificar a decisão';
      }
    },
    async reprove() {
      let itemWithDesc = cloneDeep(this.suggestionToSave);
      let itemToSave = this.remountItem(itemWithDesc);
      itemToSave.dataRegistro = new Date(); //.toISOString().slice(0, -1).replace('T', ' ');
      itemToSave.status = 'REPROVADA';
      delete itemToSave.operacao;

      let updateOk = await eSocialService.rubricahistorico.save(itemToSave);

      if (updateOk.status === 200) {
        let rubricForSuggestion = cloneDeep(this.rubricInCaseReproved);

        let rubricToSave = this.createSuggestion(rubricForSuggestion);

        await eSocialService.rubricahistorico.save(rubricToSave);
        this.$emit('close');
        this.close();
      }
    },
    close() {
      this.show = false;

      this.$emit('clean');
    },
    createSuggestion(item) {
      let rubricForSuggestion = item;

      let rubricaToSave = {};

      rubricaToSave.evtTabRubrica = { id: item.id };
      rubricaToSave.aplicabilidade = '';
      rubricaToSave.dataRegistro = new Date();
      rubricaToSave.dscRubrDadosrubrica = rubricForSuggestion.dscRubrDadosrubrica;
      rubricaToSave.natRubrDadosrubrica = rubricForSuggestion.natRubrDadosrubrica;
      rubricaToSave.iniValidIderubrica = rubricForSuggestion.iniValidIderubrica;
      rubricaToSave.fimValidIderubrica = rubricForSuggestion.fimValidIderubrica;
      rubricaToSave.codIncCPDadosrubrica = rubricForSuggestion.codIncCPDadosrubrica;
      rubricaToSave.codIncIRRFDadosrubrica = rubricForSuggestion.codIncIRRFDadosrubrica;
      rubricaToSave.codIncFGTSDadosrubrica = rubricForSuggestion.codIncFGTSDadosrubrica;
      rubricaToSave.observacaoDadosrubrica = rubricForSuggestion.observacaoDadosrubrica;
      rubricaToSave.tpRubrDadosrubrica = rubricForSuggestion.tpRubrDadosrubrica;
      rubricaToSave.codrubrIderubrica = rubricForSuggestion.codRubrIderubrica;
      rubricaToSave.userChange = this.usuario.username;
      rubricaToSave.status = 'CONCILIADA';
      rubricaToSave.justificativa = this.suggestionToSave.justificativa;

      if (rubricaToSave.iniValidIderubrica) {
        const fimValidIderubricaSplit = rubricaToSave.iniValidIderubrica.split('/');
        rubricaToSave.iniValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
      }
      if (rubricaToSave.fimValidIderubrica) {
        const fimValidIderubricaSplit = rubricaToSave.fimValidIderubrica.split('/');
        rubricaToSave.fimValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
      }
      rubricaToSave.codIncCPDadosrubrica = rubricaToSave.codIncCPDadosrubrica.substring(0, 2);
      rubricaToSave.codIncFGTSDadosrubrica = rubricaToSave.codIncFGTSDadosrubrica.substring(0, 2);
      rubricaToSave.codIncIRRFDadosrubrica = rubricaToSave.codIncIRRFDadosrubrica.substring(0, 2);
      rubricaToSave.natRubrDadosrubrica = rubricaToSave.natRubrDadosrubrica.substring(0, 4);
      rubricaToSave.tpRubrDadosrubrica = rubricaToSave.tpRubrDadosrubrica.substring(0, 1);
      return rubricaToSave;
    },
    remountItem(itemToSave) {
      itemToSave.id = '';
      if (itemToSave.iniValidIderubrica) {
        const fimValidIderubricaSplit = itemToSave.iniValidIderubrica.split('/');
        itemToSave.iniValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
      }
      if (itemToSave.fimValidIderubrica) {
        const fimValidIderubricaSplit = itemToSave.fimValidIderubrica.split('/');
        itemToSave.fimValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
      }

      itemToSave.codIncCPDadosrubrica = itemToSave.codIncCPDadosrubrica.substring(0, 2);
      itemToSave.codIncFGTSDadosrubrica = itemToSave.codIncFGTSDadosrubrica.substring(0, 2);
      itemToSave.codIncIRRFDadosrubrica = itemToSave.codIncIRRFDadosrubrica.substring(0, 2);
      itemToSave.natRubrDadosrubrica = itemToSave.natRubrDadosrubrica.substring(0, 4);
      itemToSave.tpRubrDadosrubrica = itemToSave.tpRubrDadosrubrica.substring(0, 1);

      return itemToSave;
    },
  },
};
</script>
