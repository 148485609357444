var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Justificativa de decisão ")]),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "12" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Justificativa",
                          counter: 255,
                          outlined: "",
                          "persistent-placeholder": "",
                          placeholder: _vm.obrigatorio,
                          rules: [_vm.rules.required],
                        },
                        model: {
                          value: _vm.suggestionToSave.justificativa,
                          callback: function ($$v) {
                            _vm.$set(_vm.suggestionToSave, "justificativa", $$v)
                          },
                          expression: "suggestionToSave.justificativa",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.error
                    ? _c("div", { staticClass: "summary text-red" }, [
                        _vm._v(_vm._s(_vm.error)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.close } },
                    [_vm._v(" Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        loading: _vm.loadingSave,
                        disabled: _vm.loadingSave,
                      },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" Aprovar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }